// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from 'jquery';
import "bootstrap"

require('datatables.net-bs4')
require('packs/datatables')
require('packs/intl_tel_input')

global.$ = jQuery;
global.toastr = require("toastr")

document.addEventListener("turbolinks:load", () => {
});

require("stylesheets/application.scss")
Rails.start()
Turbolinks.start()
ActiveStorage.start()

