document.addEventListener("turbolinks:load", () => {
  // Property Datatable
  if($('[id^=property-datatable]').length == 1) {
    $('#property-datatable').DataTable({
      "processing": true,
      "serverSide": true,
      "stateSave": true,
      "ajax": {
        "url": $('#property-datatable').data('source'),
        "type": 'POST'
      },
      "pagingType": "full_numbers",
      "columns": [
        { "data": "reference_number" },
        { "data": "owner_name" },
        { "data": "tax_payments", searchable: false, orderable: false },
        { "data": "actions", searchable: false, orderable: false },
      ],
     "dom": "<''<'card-body py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" +
             "<'table-responsive'<'col-sm-12'tr>>" +
             "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
      "order": [[0, "asc"]],
      language: {
        paginate: {
          next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
          previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'
        }
      }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm form-select");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block");
  }

  // Tax Payments datatable
  if($('[id^=tax-payment-datatable]').length == 1) {
    $('#tax-payment-datatable').DataTable({
      "processing": true,
      "serverSide": true,
      "stateSave": true,
      "ajax": {
        "url": $('#tax-payment-datatable').data('source'),
        "type": 'POST'
      },
      "pagingType": "full_numbers",
      "columns": [
        { "data": "receipt_number" },
        { "data": "reference_number" },
        { "data": "owner_name" },
        { "data": "tax_amount_paid" },
        { "data": "payer_first_name" },
        { "data": "payer_email" },
        { "data": "paid_on", searchable: false, orderable: false },
        { "data": "actions", searchable: false, orderable: false },
      ],
     "dom": "<''<'card-body py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" +
             "<'table-responsive'<'col-sm-12'tr>>" +
             "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
      "order": [[0, "asc"]],
      language: {
        paginate: {
          next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
          previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'
        }
      }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm form-select");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block");
  }

  // Tax Payments Report datatable
  if($('[id^=tax-payment-report-datatable]').length == 1) {
    var tax_payment_report_datatable = $('#tax-payment-report-datatable').DataTable({
      "processing": true,
      "serverSide": true,
      "stateSave": true,
      "ajax": {
        "url": $('#tax-payment-report-datatable').data('source'),
        "type": 'POST'
      },
      "pagingType": "full_numbers",
      "columns": [
        { "data": "owner_name" },
        { "data": "reference_number" },
        { "data": "current_tax" },
        { "data": "arrear_amount" },
        { "data": "paid_on", searchable: false, orderable: false },
        { "data": "actions", searchable: false, orderable: false },
      ],
     "dom": "<''B<'card-body py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" +
             "<'table-responsive'<'col-sm-12'tr>>" +
             "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
      "order": [[0, "asc"]],
      buttons: [
        'print'
      ],
      language: {
        paginate: {
          next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
          previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'
        }
      }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm form-select");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block");

    var minDate, maxDate;

    // Create date inputs
    minDate = new DateTime($('#min'), {
        format: 'MMMM DD YYYY'
    });
    maxDate = new DateTime($('#max'), {
        format: 'MMMM DD YYYY'
    });
 
    // Refilter the table
    $('#min, #max').on('change', function () {
        tax_payment_report_datatable.draw();
    });

  }


  // Generic datatables implementation
  if($('[id^=dataTable]').length == 1) {
    $('#dataTable').DataTable( {
      "dom": "<''<'card-body border-bottom py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" + 
             "<'table-responsive'<'col-sm-12'tr>>" + 
             "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
      "order": [[0, "asc"]],
      language: {
        paginate: {
          next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
          previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'  
        }
      }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block");
  } 
});
